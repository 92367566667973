import React from 'react';
import ReactDom from 'react-dom';

import Vimeo from '@u-wave/react-vimeo';

class MyPlayer extends React.Component{
	constructor (props){
		super(props);

		this.state = {
			isLoaded: false,
			film : 0,
			synopsis: '',
			keyCode: ''
		};

        this.vimeoID = 0;

    }

	componentDidMount(){
	}

    loaded(obj,el){
        this.player.player.element.focus();
        this.player.player.play();
        this.player.autoplay(true);
    }

	render(){
        const videoID = localStorage.getItem('FilmHls');
        //this.setState({film : localStorage.getItem("FilmVideo") });
        //		this.mainPlayer.focus(); // DEBUG KEY CODE
        //		this.player.handleFocus(); // VERSION FIRESTICK / TV OnLine Prod
        localStorage.removeItem("Film");
        localStorage.removeItem("FilmVideo");
        localStorage.removeItem("FilmHls");
        window.GoVideo(videoID);
       
        return(<></>)
/*
        return(
            <div 
                style={{position:"relative",width:window.innerWidth, height:window.innerHeight }}
                ref={(ref) => this.mainPlayer = ref}
                onKeyDown={(key) => { this.setState({keyCode:key.keyCode}); console.log("key : ",key ) }}
            >
                <Vimeo
                    ref={(el) => this.player=(el)}
                    video={videoID}
                    id='monPlayer'
                    autoplay={true}
                    width={window.innerWidth} 
                    height={window.innerHeight} 
                    onLoaded={this.loaded.bind(this)}
                />

            </div>
        )
        */

    }
}
export default MyPlayer
