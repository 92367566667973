import React from 'react';

import { withNavigation } from 'react-tv-navigation';
import { Focusable } from 'react-key-navigation';

import axios from 'axios';

import constants from '../../constants';

import './Connexion.css';
import { Row, Col } from 'reactstrap';

class MyButton extends React.PureComponent {
	constructor(props){
		super(props);

		this.state = {
			setActive : false
		}
	}
	onBlur() {
		this.setState({active: false});
	}	
	onFocus() {
		this.setState({active: true});
	}
	render() {
		return (
			<Focusable
				onFocus = {() => this.onFocus()} 
				onBlur = {() => this.onBlur()}  
				onEnterDown = {this.props.onEnterDown}
				onClick = {this.props.onClick}
			>
				<div 
					className = {
						"duration-hover-background form-button" 
						+ " " 
						+ (this.state.active ? "form-button-focus " : "")
					}
				>
					{this.props.text}
				</div>
			</Focusable>
		)
	}
}

class MyInput extends React.PureComponent{
	constructor(props){
		super(props);

		this.state = {
			setActive : false
		}
	}
	onBlur() {
		console.log('onBlur');
		this.setState({active: false});
	}
	onFocus() {
		console.log('onFocus');
		this.setState({active: true});
	}
	render(){
		return (
			<Focusable  
				onFocus = {() => this.onFocus()} 
				onBlur = {() => { this.onBlur(); this.nameInput.blur()}}  
				onEnterDown = {(e,n) => this.nameInput.focus()}
			>
				<label 
					class = {
							"duration-hover-background" 
								+ " " 
								+ (this.state.active ? "form-label-focus": "")
							}
					for={this.props.name}>
					{this.props.label}
				</label>

				<input
					autofocus="true"
					className = {
						"duration-focus-border form-input" 
						+ " " 
						+ (this.state.active ? "form-input-focus": "")
					}
					onFocus = {() => this.onFocus()}
					onBlur = {() => this.onBlur()}
					autoComplete = {'off'} 
					type = {this.props.type}
					name = {this.props.name}
					ref = {(input) => { this.nameInput = input;}}
					onKeyDown = {this.props.onKeyDown}
				/>
			</Focusable> 
		)
	}
}

class Connexion extends React.PureComponent{
	constructor(props){
		super(props);

		this.state = {
			email:'',
			pass: '',
			connexionEnabled: true,
			hover_iMail : false,
			hover_iPass : false,
			lienSimple	: false,
			message:'',
			spinnerDisplay: 'none',
		}
		this.verifyUser = this.verifyUser.bind(this);
		this.device = null;
	}

	verifyUser(){
		this.setState({spinnerDisplay:'block'});
		if(this.state.email.trim() === ''){
			this.setState({message : "L'adresse Email doit être renseignée",spinnerDisplay:'none'});
		}else if(this.state.pass.trim()===''){
			this.setState({message : "Le mot de passe doit être renseigné",spinnerDisplay:'none'});
		}else{
			axios.get("https://vod-dev.zone300.com/api/auth_v4.1_mobile.php?username=" + this.state.email + "&password=" + this.state.pass )
			.then(res => {
				//
				console.log("result : ",res);
				this.setState({spinnerDisplay:'none'});
				if(res.data.ID){
					// ICI on se logue
					localStorage.setItem("LOGIN_TOKEN",JSON.stringify(res.data));
					localStorage.setItem("IS_GUEST", res.data.is_guest);
					localStorage.setItem("NO_CLIENT", res.data.NoCli);
					if(this.device){
						window.location.assign("/?device=" + this.device);
					}else{
						window.location.assign("/");
					}
				}else{
					this.setState({message:"Email et/ou mot de passe incorrect"});
				}
			});
		}
	}

	render(){
		this.device = localStorage.getItem('device');
		return(
			<div style={{backgroundColor:"#000", width:constants.LARGEUR, height:constants.HAUTEUR }}>
				<div style={{textAlign:'center',fontFamily:'Balboa'}}>
					<div>
						<img alt='' src={require('../../assets/img/logo-24.png')} style={{marginTop:'6vh',marginBottom:'3vh'}} />
					</div>
					<div style={{fontSize:'5vh', fontFamily:'Balboa', color:"#FBA00B"}}>Se connecter</div>
					<div style={{color:'#fff', fontSize:'3vh',marginTop:'4vh', }}>Renseigne ton email et ton mot de passe et accède à tes films de chasse, pêche et chasse sous-marine préférés !</div>
				</div>
				<div style={{marginTop:50}}>
					<Row style={{}}>
						<Col sm="12" md={{ size: 6, offset: 3 }} >
							<MyInput type="text" label="Email" name="email" onKeyDown={ (text) => this.setState({email : text.target.value,message:''}) }/>
							<div style={{margin:20}}></div>
							<MyInput type="password" label="Mot de passe" name="passwd" onKeyDown={ (text) => this.setState({pass : text.target.value,message:''}) } />
						</Col>
					</Row>
				</div>
				<div style={{textAlign:'center',fontFamily:'Balboa',color:"#FBA00B",fontSize:'3.5vh',marginTop:'4vh'}}>
					{this.state.message}
					<div className="loader center" style={{marginTop:'4vh',display:this.state.spinnerDisplay}}>
						<i className="fa fa-spinner fa-pulse" style={{fontSize:'8vh'}}/>
					</div>
				</div>

				<MyButton text="GO !" onEnterDown={this.verifyUser} onClick={this.verifyUser} />
			</div>
		)
	}
}

export default withNavigation(Connexion);
