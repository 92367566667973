import React from 'react';
import { Focusable, VerticalList } from 'react-key-navigation';



class ToogleItem extends React.Component {
	constructor() {
		super();

		this.state = {
			active: false,
			selected: false,
		}
		this._onEnterDown = this._onEnterDown.bind(this);
		this.timer = null;
	}

	_onEnterDown(obj,navig){
//		console.log("_onEnterDown : ", navig)
//		console.log("_onEnterDown this : ",this)

//		this.setState({selected:true});
//		console.log("_onEnterDown this.parent : ",this.parent);
		
		this.props.parent.setState({Categ: this.props.keyId});

		const fnc = this.props.testFnc;
		fnc(this.props.keyId);

		navig.forceFocus( 'mainBox' );
	}

	render() {
		return (
			<Focusable 
				onFocus={() => this.setState({active: true}) }
				onBlur={() => this.setState({active: false})}
				onEnterDown={this._onEnterDown}
				onSelect={() => this.setState({selected: true})}
				onDeSelect={() => this.setState({selected: false})}
				{...this.props}
			>
				<div className={'item ' + (this.state.active ? 'item-focus' : '') + (this.state.selected ? ' item-selected' : '')}>
					<i className={this.props.icon}></i> {this.props.children}
				</div>
			</Focusable>
		);
	}
};

export default class Sidebar extends React.Component {
	constructor() {
		super();

		this.state = {
		active: false
		}
		this.onGo = this.onGo.bind(this);
	}

	componentDidMount(){
	}

	setActive(status) {
		this.setState({active: status});
	}

	disconnect(boom){
		var device = localStorage.getItem("device");
		localStorage.clear();
		if(device){
			window.location = "/?device=" + device;
		}else{
			window.location = "/";
		}
	}

	onGo(ou){
		const myRef = this.refs.mySide.children;
		myRef.map((item) =>{
			if(item.props.keyId === ou){
				item.props.onSelect();
				//item.setState({selected:true})
			}else{
				item.props.onDeSelect();
			}
		})
		localStorage.setItem("menuKey",ou);
//		console.log("onGo this.props.parent : ",this.props.parent," ou : ",ou);
//		this.props.parent.setState({Categ: ou});
//		console.log("onGo() => ",ou,myRef)
	}
	componentDidMount(){
		var ou = localStorage.getItem("menuKey");
		if(ou !== ''){
			this.onGo(ou)
		}

		if(this.props.Categ){
			console.log(" dans categ : ", this.refs[this.props.Categ] ) ;
			this.onGo(this.props.Categ)
		}
	}
	render() {
		return (
			<div id="sidebar" className={this.state.active ? 'focused' : ''}>
				<div id="icons">
					<div><span className="fa fa-home"></span></div>
					<div><span className="myicon myicon-chasse"></span></div>
					<div><span className="myicon myicon-peche"></span></div>
					<div><span className="fa fa-search"></span></div>
					<div><span className="fa fa-sign-out-alt"></span></div>
				</div>
				<div id="menu">
					<div className="logoMenu">
						<img alt='' src={require('../../assets/img/logo-24_p.png')} />
					</div>
					<VerticalList 
						ref='mySide'
						onFocus={() => this.setActive(true)}
						onBlur={() => this.setActive(false)} 
						focusId="sidebar" 
						retainLastFocus={true}
						
					>
						<ToogleItem 
							ref="monZ3" 
							testFnc={this.onGo} 
							keyId="monZ3" 
							icon="fa fa-home"
							parent={this.props.parent}
						>Mon Zone300</ToogleItem>

						<ToogleItem 
							ref="chasse"
							testFnc={this.onGo} 
							keyId="chasse"
							icon="myicon myicon-chasse"
							parent={this.props.parent}
						>Chasse</ToogleItem>

						<ToogleItem 
							ref="peche"
							testFnc={this.onGo} 
							keyId="peche"
							icon="myicon myicon-peche"
							parent={this.props.parent}
						>Pêche</ToogleItem>

						<ToogleItem 
							ref="search"
							testFnc={this.onGo} 
							keyId="search"
							icon="fa fa-search"
							parent={this.props.parent}
						>Recherche</ToogleItem>

						<ToogleItem 
							ref="disconnect"
							testFnc={this.disconnect} 
							keyId="disconnect"
							icon="fa fa-sign-out-alt"
							parent={this.props.parent}
						>Déconnexion</ToogleItem>

					</VerticalList>
				</div>
			</div>
		);
	}
}
