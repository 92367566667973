import React from 'react';


import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import { withFocusable, withNavigation } from 'react-tv-navigation';
import { VerticalList, Focusable } from 'react-key-navigation';

import axios from 'axios';

import './LandingPage.css'; 

const hauteur = window.innerHeight;
const largeur = window.innerWidth;

const responsive = {
	desktop: {
	  breakpoint: { max: 3000, min: 1024 },
	  items: 1,
	  slidesToSlide: 1 // optional, default to 1.
	},
	tablet: {
	  breakpoint: { max: 1024, min: 464 },
	  items: 1,
	  slidesToSlide: 1 // optional, default to 1.
	},
	mobile: {
	  breakpoint: { max: 464, min: 0 },
	  items: 1,
	  slidesToSlide: 1 // optional, default to 1.
	}
  };

const Button = ({setFocus, focusPath}) => {
	return (
			<a 
				className="ButtonGO duration-hover-background"
				onClick={() => { 
					setFocus('item-1') 
				}}
				href="/connexion/"
			>
				Connexion
			</a>
	)
  }

const FocusableButton = withFocusable(Button);

function _renderMap(img){
		
	const MyHauteur = hauteur - 48; // Math.round( (hauteur * 95) / 100);
	
	return ( 
		<Focusable key={img}>
			<div style={{width:largeur, height: ( MyHauteur)}}>
				<img 
					alt='' 
					style={{ width: largeur, height:( MyHauteur ), alignSelf: 'center'}} 
					src={"https://admin-vod.zone300.com/api/cimage/webroot/img.php?src=../../../DATA/TMP/img-tv/" + img} 
				/>
			</div>
		</Focusable>
	)
}

const Slider = ({setFocus, imagesExemple }) => {
	return (
		<Carousel 
			responsive = {responsive}
			showDot = {true}
			dotListClass = "custom-dot-list-style"
		>
			{ imagesExemple.map( (img) => _renderMap(img) )}
		</Carousel>
	)
}

const FocusableSlider = withFocusable(Slider);

class Landing extends React.PureComponent {

	constructor(props){
		super(props);

		this.state = {
			lastEventType: '',
			imagesExemple: [],
			isLoaded: false
		}
	}

	componentWillMount(){
		axios.get('https://admin-vod.zone300.com/api/appli/getAccueil.php')
		.then( res => {
			this.setState({ imagesExemple: res.data, isLoaded: true });
		});
	}

	_renderMap(img){
		return ( 
			<Focusable key={img}>
				<div style={{width:largeur, height: ( hauteur)}} /*{width, height: hp('92%') - getStatusBarHeight() }*/>
					<img alt='' style={{ width: largeur, height:( hauteur ), alignSelf: 'center'}} src={"https://admin-vod.zone300.com/api/cimage/webroot/img.php?src=../../../DATA/TMP/img-tv/" + img} />
				</div>
			</Focusable>
		)
	}


	render(){
		return(
			<VerticalList navDefault>
				{ this.state.isLoaded && 
					<div id="LandingPage" style={{backgroundColor:"#000",width:largeur,height:hauteur}}>
						<FocusableSlider focusPath="Carousel" imagesExemple={this.state.imagesExemple} />
						<FocusableButton ref={(connexion) =>{ this.idConnexion = connexion}} focusPath="/connexion/" key="Go" onEnterPress={() => { window.location.assign("/connexion/")}} />
					</div>
				}
                { !this.state.isLoaded && 
                	<div style={{width:'100%',height:'100%',textAlign:'center',marginTop:'50vh'}}>
						<div className="loader center" style={{marginTop:'4vh'}}>
							<i className="fa fa-spinner fa-pulse" style={{fontSize:'8vh',color:'#FBA00B'}}/>
						</div>

					</div> 
				}
			</VerticalList>
		)
	}
}

export default withNavigation(Landing)
//export default Landing;
	